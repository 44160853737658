<template>
  <div class="result_content">
    <div class="battle_result">
      <h3 v-if="battleResult.isWin">你太棒啦！继续保持哦～</h3>
      <h3 v-if="!battleResult.isWin">再试一次，一定会比刚才更好！</h3>
      <div class="result_score">
        <div class="score_item">
          <div class="score star">
            <div class="starimg">
              <img src="./images/star2x.png" alt="" />
            </div>
            <!-- <h4>+{{ battleResult.score }}</h4> -->
            <h4>+{{ star }}</h4>
          </div>
          <h5 class="marginleft15">星值</h5>
        </div>
        <div class="score_item">
          <div class="score integral">
            <div class="integralimg">
              <img src="./images/integral2x.png" alt="" />
            </div>
            <h4>+{{ battleResult.point }}</h4>
          </div>
          <h5 class="marginleft10">积分</h5>
        </div>
      </div>
      <div class="result_topic">
        <p
          :class="wordarr.isRight ? '' : 'wrong'"
          v-for="(wordarr, index) in battleResult.wordList"
          :key="index"
        >
          {{ wordarr.word }}&nbsp;&nbsp;{{ wordarr.meaning }}
        </p>
      </div>
      <div class="buttons">
        <div class="button_green" @click="goDown">去下载APP</div>
        <div class="button_yello" @click="dialogshow = !dialogshow">
          把战绩同步到账号
        </div>
      </div>
      <div class="cup">
        <img v-if="battleResult.isWin" src="./images/win.png" alt="" />
        <img v-if="!battleResult.isWin" src="./images/fail.png" alt="" />
      </div>
    </div>
    <van-dialog
      v-model="dialogshow"
      overlay
      width="89.7vw"
      className="sefdiastyle"
      confirmButtonColor=""
      theme="round-button"
      close-on-click-overlay
      overlay-class="selfoverlay"
      :showConfirmButton="false"
    >
      <div class="dialogcontent">
        <div class="closebut clearfix" @click="closeDialog">
          <img src="./images/close.png" alt="" />
        </div>
        <h1>登录/注册</h1>
        <van-form @submit="onSubmit">
          <van-field
            class="teleinput selfstyle"
            name="+86"
            label="+86"
            v-model="changemobile.mobile"
            type="tel"
            center
            style="
              padding-left: 50px;
              border-radius: 21px;
              background: rgba(238, 238, 238, 1);
              padding-top: 0;
              padding-bottom: 0;
              margin-bottom: 5px;
            "
            placeholder="请输入手机号"
            autofocus
            label-width="36px"
            format-trigger="onBlur"
          />
          <!-- :rules="[
              { required: true, message: '请填写手机号' },
              { validator, message: '手机格式错误' },
            ]" -->
          <!-- :rules="[{ required: true, message: '请填写短信验证码' }]" -->

          <van-field
            class="smscodebutton selfstyle"
            v-model="changemobile.smsCode"
            center
            clearable
            format-trigger="onBlur"
            placeholder="请输入短信验证码"
            border
          >
            <template #button>
              <van-button
                type="default"
                size="small"
                @click.prevent="getSMSNew()"
                >{{ changemobile.text }}</van-button
              >
            </template>
          </van-field>
          <!-- <van-field name="radio" style="padding: 10px 0">
            <template #input>
              <van-radio-group
                v-model="radio1"
                direction="horizontal"
                @change="onChange"
              >
                <van-radio name="1"
                  >我已阅读并同意<span class="selftypetongyi"
                    >《用户服务协议》《登录政策》</span
                  ></van-radio
                >
              </van-radio-group>
            </template>
          </van-field> -->

          <van-field name="checkbox">
            <template #input>
              <van-checkbox v-model="radio1"
                >我已阅读并同意<span class="selftypetongyi"
                  >《用户服务协议》《登录政策》</span
                ></van-checkbox
              >
            </template>
          </van-field>

          <div style="margin: 16px" class="loginbutton">
            <van-button round block type="info" native-type="submit" center
              >立即登录</van-button
            >
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, Field, Form, Radio, Button, RadioGroup, Notify, Checkbox } from 'vant'
import 'vant/lib/index.css'
import {
  smsCode, changeMobile,
} from "@/api"

export default {
  name: 'BattleResult',
  components: {
    "van-dialog": Dialog.Component,
    "van-field": Field, //没有Component
    "van-form": Form,
    "van-radio": Radio,
    "van-button": Button,
    "van-radio-group": RadioGroup,
    "van-notify": Notify,
    "van-checkbox": Checkbox
    // [Field.name]: Field,

  },

  data () {
    return {
      dialogshow: false,

      radio1: true,
      ac: '86',
      changemobile: {
        mobile: "",
        smsCode: "",
        text: "发送验证码",
        timer: null,
        uuid: ""
      },

      battleId: '169',
      num: 0, //第几题从0开始
      star: 0,

      battleResult: {}
    }
  },
  mounted () {
    this.battleResult = this.$route.params.battleResult ? JSON.parse(this.$route.params.battleResult) : ''
    this.star = this.battleResult && this.battleResult.isWin ? "1" : "0"
  },
  methods: {
    goDown () {
      window.location.href = "https://sj.qq.com/appdetail/com.unodostech.dictApp"
    },
    closeDialog () {
      console.log("dfsdfs")
      this.dialogshow = false
    },
    onChange (event) {
      this.radio = event
    },

    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator (val) {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val)
    },


    onSubmit () {
      let { mobile, smsCode, uuid } = this.changemobile
      if (!mobile) {
        this.$notify("请填写手机号")
        return
      }
      if (!smsCode) {
        this.$notify("请填写短信验证码")
        return
      }
      // if (!/^[0-9]*$/.test(mobile)) {
      if (!/^[1][35789][0-9]{9}$/.test(this.changemobile.mobile)) {
        this.$notify("请输入正确手机号")
        return
      }

      if (!this.radio1) {
        this.$notify("请先勾选同意后再登录")
        return
      }

      changeMobile({ mobile: this.ac + mobile, smsCode, uuid, scene: "BIND", from: "wx" }).then((res) => {
        if (res.code == 200) {
          this.$message.success("登录成功")
          this.dialogshow = false
          if (res.token) {
            localStorage.setItem("token", "Bearer " + res.token)
          }
          // this.getUserInfo()
        } else {
          // this.$message.error(res.msg)
          // Notify({ type: 'danger', message: res.msg })

          this.$notify(res.msg)
          this.dialogshow = false
        }
      })

    },
    // 微信登录绑定手机号发送
    getSMSNew () {
      if (!/^[1][35789][0-9]{9}$/.test(this.changemobile.mobile)) {
        // if (!/^[0-9]*$/.test(this.changemobile.mobile)) {
        // this.$message.error("请输入正确手机号")
        this.$notify("请输入正确手机号")
        return
      }

      if (this.changemobile.text === "发送验证码") {
        smsCode({
          mobile: this.ac + this.changemobile.mobile,
          scene: "BIND"
        }).then(res => {
          if (res.code == 500) {
            this.$message.error(res.msg)
            return
          }
          let num = 60
          this.changemobile.timer = setInterval(() => {
            num--

            if (num === 0) {
              clearInterval(this.changemobile.timer)
              this.changemobile.timer = null
              this.changemobile.text = `发送验证码`
            }
            else {
              this.changemobile.text = `重新发送(${num}s)`
              // console.log(num,'num');
            }
          }, 1000)

          this.changemobile.uuid = res.uuid
          // this.form.smsCode = res.smsCode
        })
      } else {
      }
    },


  },

}
</script>

<style lang="less" scoped>
/deep/.van-field__label {
  position: absolute;
  top: 12px;
  left: 16px;
  z-index: 9999;
  height: 18px;
  line-height: 18px;
  border-right: 1px solid rgba(153, 153, 153, 1);
}
// elementui
/deep/.el-input__inner {
  border: none;
  background: rgba(238, 238, 238, 1);
  border-radius: 0 20px 20px 0;
}
/deep/.el-select .el-input {
  width: 55px;
}
/deep/.el-input__suffix {
  display: none;
}
/deep/.el-input-group__prepend div.el-select .el-input__inner {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(29, 29, 31, 1);
}
/deep/.el-select .el-input__inner {
  padding-left: 16px;
  padding-right: 0px;
}
// /deep/.el-input-group__append,
/deep/.el-input-group__prepend {
  border: none;
  background: rgba(238, 238, 238, 1);
  border-radius: 20px 0 0 20px;
}
// elementui
/deep/.van-cell::after {
  border-bottom: none;
}
/deep/.van-radio--horizontal {
  margin: 0 4px;
}
.selfstyle {
  /deep/.van-field__body {
    // width: 280px;
    height: 42px;

    border-radius: 21px;
    background: rgba(238, 238, 238, 1);
    padding: 0 16px;
  }
}
.loginbutton {
  // width: 120px;
  // height: 40px;
  // width: 100%;
  display: flex;
  justify-content: center;
  opacity: 1;
  border-radius: 20px;

  /deep/.van-button--info {
    width: 120px;
    height: 40px;
    text-align: center;
    background: linear-gradient(
      90deg,
      rgba(100, 162, 250, 1) 0%,
      rgba(114, 144, 255, 1) 100%
    );
    border: none;
  }
}
// /deep/.van-cell--center {
//   // border-radius: 21px;
//   background: rgba(238, 238, 238, 1);
//   margin-top: 11px;
// }
/deep/.selfoverlay {
  opacity: 0.5;
  background: rgba(0, 0, 0, 1);
}

/deep/.van-field__error-message {
  color: rgba(212, 48, 48, 1);
  padding: 0 16px;
}

/deep/.van-field--error .van-field__control,
/deep/.van-field--error .van-field__control::placeholder {
  color: rgba(212, 48, 48, 1);
}

/deep/.teleinput {
  /deep/.van-field--error .van-field__control {
    padding-left: 40px;
  }
}

/deep/.selftypetongyi {
  color: rgba(29, 29, 31, 1);
}
/deep/.van-checkbox__label {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 15.4px;
  color: rgba(112, 112, 112, 1);
}
/deep/.van-checkbox__icon .van-icon {
  width: 12px;
  height: 12px;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  // opacity: 1;
  // background: rgba(254, 254, 254, 1);

  // border: 1px solid rgba(204, 202, 200, 1);
}
.sefdiastyle {
  max-width: 350px;
}
.result_content {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(229, 251, 243, 1) 0%,
    rgba(241, 255, 239, 1) 60.72%,
    rgba(247, 247, 247, 1) 100%
  );
  padding: 100px 4.1vw 38px;
  .battle_result {
    width: 100%;
    min-height: 100px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 1);
    padding: 67px 3.1vw 45px;
    position: relative;
    h3 {
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(29, 29, 31, 1);
      text-align: center;
    }
    .result_score {
      width: 100%;
      height: 91px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(247, 252, 248, 1);
      margin-top: 13px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .score_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        // align-content: center;
        .score {
          display: flex;
          align-items: center;
        }
        .integral {
          .integralimg {
            // width: 39px;
            // height: 39px;
            width: 20.34px;
            height: 22.04px;
          }
          // img {
          //   margin-left: 9px;
          // }
        }
        .star {
          .starimg {
            // width: 39px;
            // height: 39px;
            width: 26px;
            height: 26px;

            // text-align: right;
            // img {
            //   width: 26px;
            //   height: 26px;
            //   margin-top: 6px;
            // }
          }
        }

        h4 {
          font-size: 28px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 33.91px;
          color: rgba(29, 29, 31, 1);
        }
        h5 {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 16.8px;
          color: rgba(119, 119, 119, 1);
        }
        .marginleft10 {
          margin-left: 6px;
        }
        .marginleft15 {
          margin-left: 22px;
        }
      }
    }
    .result_topic {
      width: 100%;
      // height: 277px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(247, 252, 248, 1);
      margin-top: 13px;
      padding: 11px 5.1vw;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 2;
      color: rgba(0, 0, 0, 1);
      .wrong {
        color: rgba(212, 48, 48, 1);
      }
    }
    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 21px;
      .button_green {
        width: 30.77vw;
        height: 33px;
        line-height: 33px;
        opacity: 1;
        border-radius: 37.5px;
        background: linear-gradient(
          180deg,
          rgba(114, 237, 163, 1) 0%,
          rgba(69, 204, 123, 1) 100%
        );
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
      }
      .button_yello {
        width: 30.77vw;
        height: 33px;
        line-height: 33px;
        opacity: 1;
        border-radius: 37.5px;
        background: linear-gradient(
          180deg,
          rgba(254, 227, 42, 1) 0%,
          rgba(255, 176, 3, 1) 100%
        );
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .cup {
      position: absolute;
      top: -84px;
      left: 50%;
      transform: translateX(-50%);
      width: 199.23px;
      height: 138px;
    }
  }
}

.dialogcontent {
  width: 100%;
  height: 100%;
  padding: 23px 35px 2px;
  .smscodebutton {
    /deep/.van-button--default {
      border: none;
      background-color: transparent;
    }
  }
  /deep/.van-cell {
    // height: 42px;
    // box-sizing: border-box;
    padding: 5px 0;
  }
  .closebut {
    width: 10px;
    height: 10px;
    float: right;
  }
  h1 {
    margin-top: 17px;
    margin-bottom: 7px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(29, 29, 31, 1);

    text-align: left;
    vertical-align: top;
  }
}
/deep/.van-goods-action-button--danger {
  background: linear-gradient(
    90deg,
    rgba(100, 162, 250, 1) 0%,
    rgba(114, 144, 255, 1) 100%
  );
  width: 120px;
  height: 40px;
}
</style>